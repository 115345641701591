import { Button, useModal, IngressRouteIcon } from 'ui'
import WorkloadRunIngressForm from './WorkloadRunIngressForm'
import { WorkloadDefinitionType, WorkloadRunType } from 'sdk'

interface WorkloadRunIngressButtonProps {
  workloadDefinition: WorkloadDefinitionType
  workloadRun: WorkloadRunType
  buttonText?: string
}

export default function WorkloadRunIngressButton({
  workloadDefinition,
  workloadRun,
  buttonText,
}: WorkloadRunIngressButtonProps): JSX.Element {
  const { openModal, closeModal } = useModal()

  const Component = (
    <WorkloadRunIngressForm
      workloadDefinition={workloadDefinition}
      workloadRun={workloadRun}
      onCancel={closeModal}
    />
  )

  return (
    <Button onClick={() => openModal({ Component })} style={{ gap: '4px' }}>
      {<IngressRouteIcon />}
      {buttonText && buttonText}
    </Button>
  )
}
