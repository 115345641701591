import { useWorkloadRunDeleteMutation, WorkloadRunType } from 'sdk'
import { ButtonWithConfirm, DeleteIcon } from 'ui'
import { useQueryClient } from '@tanstack/react-query'

interface WorkloadRunDeleteButtonProps {
  teamId: string
  workloadRun: WorkloadRunType
  buttonText?: string
}
interface MutationError {
  message: string
}
export default function WorkloadRunDeleteButton({
  teamId,
  workloadRun,
  buttonText,
}: WorkloadRunDeleteButtonProps): JSX.Element {
  const queryClient = useQueryClient()

  const displayName =
    workloadRun.createdBy.firstName && workloadRun.createdBy.lastName
      ? `${workloadRun.createdBy.firstName} ${workloadRun.createdBy.lastName}`
      : workloadRun.createdBy.username
  const { mutateAsync } = useWorkloadRunDeleteMutation<MutationError>()

  return (
    <ButtonWithConfirm
      buttonLabel={'Delete'}
      buttonText={
        <>
          <DeleteIcon />
          <p>{buttonText}</p>
        </>
      }
      prompt={'Are you sure you want to delete the following workload?'}
      disabled={workloadRun.ingressrouteSet.length > 0}
      onSubmit={async () => {
        const error = await mutateAsync({
          input: {
            id: workloadRun.id,
            teamId: teamId,
          },
        })
          .then(() => null)
          .catch((error) => `Failed to delete workload: ${error.message}`)
        await queryClient.invalidateQueries(['workloadRuns'])
        return error
      }}
      promptItems={
        workloadRun.namespace
          ? [`Workload: ${workloadRun.namespace}`, `Created By: ${displayName}`]
          : []
      }
      comments={workloadRun.comments ? workloadRun.comments : undefined}
      style={{ gap: '4px' }}
    />
  )
}
