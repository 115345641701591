import { WorkloadRunType } from 'sdk'
import { Button, CommentIcon, useModal } from 'ui'
import WorkloadRunCommentForm from './WorkloadRunCommentForm'

interface WorkloadRunCommentUpdateButtonProps {
  workloadRun: WorkloadRunType
  buttonText?: string
}

export default function WorkloadRunCommentUpdateButton({
  workloadRun,
  buttonText,
}: WorkloadRunCommentUpdateButtonProps): JSX.Element {
  const { openModal, closeModal } = useModal()
  const Component = (
    <WorkloadRunCommentForm workloadRun={workloadRun} onCancel={closeModal} />
  )

  return (
    <Button onClick={() => openModal({ Component })} style={{ gap: '4px' }}>
      {<CommentIcon />}
      {buttonText && buttonText}
    </Button>
  )
}
