import { FormEventHandler, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import {
  useIngressRouteAssignMutation,
  useIngressRoutesQuery,
  WorkloadDefinitionType,
  WorkloadRunType,
} from 'sdk'
import {
  Button,
  ButtonAsync,
  ButtonRow,
  Code,
  Form,
  FormError,
  Label,
  Link,
  Select,
  SubHeading,
} from 'ui'

interface WorkloadRunDeleteFormProps {
  onCancel: () => void
  workloadDefinition: WorkloadDefinitionType
  workloadRun: WorkloadRunType
}

interface MutationError {
  message: string
}

export default function WorkloadRunIngressForm({
  onCancel,
  workloadDefinition,
  workloadRun,
}: WorkloadRunDeleteFormProps): JSX.Element {
  const queryClient = useQueryClient()
  const [submitError, setSubmitError] = useState<string | null>(null)
  const [ingressRouteId, setIngressRouteId] = useState<string>('')

  const { mutateAsync, isLoading } =
    useIngressRouteAssignMutation<MutationError>({
      onSuccess: () => {
        setIngressRouteId('')
        return Promise.all([
          queryClient.invalidateQueries(['workloadRuns']),
          queryClient.invalidateQueries(['ingressRoutes']),
        ])
      },
    })

  const ingressRoutesQuery = useIngressRoutesQuery({
    workloadDefinitionId: workloadDefinition.id,
  })

  if (ingressRoutesQuery.isLoading) {
    return <p>Loading...</p>
  }
  if (!ingressRoutesQuery.isSuccess) {
    return <p>Loading failed, please reload to try again</p>
  }

  const ingressRoutes = ingressRoutesQuery.data.ingressRoutes.edges.map(
    (edge) => edge.node
  )

  const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault()
    const errors: string[] = []
    await mutateAsync({
      input: {
        id: ingressRouteId,
        workloadRunId: workloadRun.id,
      },
    }).catch((error) => errors.push(error.message))
    if (errors.length > 0) {
      setSubmitError(errors.join(', '))
    }
  }

  const displayName =
    workloadRun.createdBy.firstName && workloadRun.createdBy.lastName
      ? `${workloadRun.createdBy.firstName} ${workloadRun.createdBy.lastName}`
      : workloadRun.createdBy.username

  if (ingressRoutes === null) {
    return <p>Loading ingress routes...</p>
  }

  const assignedIngressRoutes = ingressRoutes.filter(
    (ingressRoute) =>
      ingressRoute.workloadRun && ingressRoute.workloadRun.id === workloadRun.id
  )

  const selectedIngressRoute = ingressRoutes.find(
    (ingressRoute) => ingressRoute.id === ingressRouteId
  )

  return (
    <Form onReset={onCancel} onSubmit={onSubmit}>
      <SubHeading>Assign Ingress Routes</SubHeading>
      <p>
        Updating ingress routes for <Code>{workloadRun.namespace}</Code> by{' '}
        {displayName}
      </p>
      <Label>Assigned Ingress Routes</Label>
      <table>
        {assignedIngressRoutes.length === 0 ? (
          <tr>
            <td>No ingress routes assigned to this workload run.</td>
          </tr>
        ) : (
          assignedIngressRoutes.map((ingressRoute) => (
            <tr key={ingressRoute.id}>
              <td>
                <Link
                  href={ingressRoute.url}
                  rel={'noreferrer'}
                  target={'_blank'}
                >
                  {ingressRoute.url}
                </Link>
              </td>
              <td>
                <ButtonAsync
                  type={'button'}
                  disabled={isLoading}
                  loading={isLoading}
                  danger={true}
                  onClick={(event) => {
                    event.preventDefault()
                    return mutateAsync({
                      input: {
                        id: ingressRoute.id,
                        workloadRunId: null,
                      },
                    })
                  }}
                >
                  Unassign
                </ButtonAsync>
              </td>
            </tr>
          ))
        )}
        <tr>
          <td>
            <Select
              title={'Workload Ingress Route'}
              value={ingressRouteId}
              onChange={(event) => {
                setIngressRouteId(event.currentTarget.value)
              }}
            >
              {
                <>
                  <option value={''} disabled>
                    Select ingress route...
                  </option>
                  {ingressRoutes
                    .filter(
                      (ingressRoute) =>
                        !assignedIngressRoutes.find(
                          (assignedIngressRoute) =>
                            ingressRoute.id === assignedIngressRoute.id
                        )
                    )
                    .map((ingressRoute) => (
                      <option key={ingressRoute.id} value={ingressRoute.id}>
                        {ingressRoute.url}
                        {ingressRoute.workloadRun?.namespace &&
                          ` assigned to ${ingressRoute.workloadRun.namespace}`}
                      </option>
                    ))}
                </>
              }
            </Select>
          </td>
          <td>
            <ButtonAsync
              type={'submit'}
              disabled={isLoading || ingressRouteId.length === 0}
              loading={isLoading}
            >
              Assign
            </ButtonAsync>
          </td>
        </tr>
        {selectedIngressRoute?.workloadRun && (
          <tr>
            <td colSpan={2}>
              <FormError>
                You are about to re-assign an active ingress route!
              </FormError>
            </td>
          </tr>
        )}
      </table>
      {submitError != null && (
        <FormError>Failed to assign ingress route: {submitError}</FormError>
      )}
      <ButtonRow>
        <Button type={'reset'}>Close</Button>
      </ButtonRow>
    </Form>
  )
}
