import { FormEventHandler, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useWorkloadRunCommentUpdateMutation, WorkloadRunType } from 'sdk'
import {
  Button,
  ButtonAsync,
  ButtonRow,
  Code,
  Form,
  FormError,
  SubHeading,
  TextArea,
} from 'ui'

interface WorkloadRunCommentFormProps {
  onCancel: () => void
  workloadRun: WorkloadRunType
}

interface MutationError {
  message: string
}

export default function WorkloadRunCommentForm({
  onCancel,
  workloadRun,
}: WorkloadRunCommentFormProps): JSX.Element {
  const queryClient = useQueryClient()

  const [comments, setComments] = useState(
    workloadRun.comments ? workloadRun.comments : ''
  )
  const [submitError, setSubmitError] = useState<string | null>(null)

  const { mutateAsync, isLoading } =
    useWorkloadRunCommentUpdateMutation<MutationError>({
      onSuccess: () => {
        return Promise.all([queryClient.invalidateQueries(['workloadRuns'])])
      },
    })

  const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault()
    const errors: string[] = []
    await mutateAsync({
      input: {
        id: workloadRun.id,
        comments: comments,
      },
    }).catch((error) => errors.push(error.message))
    if (errors.length > 0) {
      setSubmitError(errors.join(', '))
    } else {
      onCancel()
    }
  }

  const displayName =
    workloadRun.createdBy.firstName && workloadRun.createdBy.lastName
      ? `${workloadRun.createdBy.firstName} ${workloadRun.createdBy.lastName}`
      : workloadRun.createdBy.username

  return (
    <Form onReset={onCancel} onSubmit={onSubmit}>
      <SubHeading>Update Workload Run Comments</SubHeading>
      <p>
        Updating comments for <Code>{workloadRun.namespace}</Code> by{' '}
        {displayName}
      </p>
      <TextArea
        id="comments"
        name="comments"
        value={comments}
        onChange={(event) => setComments(event.currentTarget.value)}
      />
      <ButtonAsync loading={isLoading} type="submit">
        Set Comments
      </ButtonAsync>
      {submitError != null && (
        <FormError>Failed to set comments: {submitError}</FormError>
      )}
      <ButtonRow>
        <Button type={'reset'}>Close</Button>
      </ButtonRow>
    </Form>
  )
}
