import { useWorkloadRunRestartMutation, WorkloadRunType } from 'sdk'
import { ButtonWithConfirm, RestartIcon } from 'ui'
import { useQueryClient } from '@tanstack/react-query'

interface WorkloadRunRestartButtonProps {
  workloadRun: WorkloadRunType
  buttonText?: string
}

interface MutationError {
  message: string
}
export default function WorkloadRunRestartButton({
  workloadRun,
  buttonText,
}: WorkloadRunRestartButtonProps): JSX.Element {
  const queryClient = useQueryClient()

  const displayName =
    workloadRun.createdBy.firstName && workloadRun.createdBy.lastName
      ? `${workloadRun.createdBy.firstName} ${workloadRun.createdBy.lastName}`
      : workloadRun.createdBy.username

  const { mutateAsync } = useWorkloadRunRestartMutation<MutationError>()

  const onSubmit = async () => {
    const error = await mutateAsync({
      input: {
        id: workloadRun.id,
      },
    })
      .then(() => null)
      .catch((error) => `Failed to restart workload run: ${error.message}`)
    await queryClient.invalidateQueries(['workloadRuns'])
    return error
  }

  return (
    <ButtonWithConfirm
      buttonLabel={'Restart Workload'}
      buttonText={
        <>
          <RestartIcon />
          <p>{buttonText}</p>
        </>
      }
      prompt={
        'Are you sure you want to restart the following workload?\nThe workload itself will not be deleted, but all state in current pod is lost.\nThe URL will be maintained.'
      }
      onSubmit={onSubmit}
      promptItems={
        workloadRun.namespace
          ? [`Workload: ${workloadRun.namespace}`, `Created By: ${displayName}`]
          : []
      }
      style={{ gap: '4px' }}
    />
  )
}
