import { MouseEventHandler, useContext, useState } from 'react'
import {
  WorkloadDefinitionType,
  WorkloadRunLifecycleStatus,
  WorkloadRunType,
} from 'sdk'
import {
  CheckCircleInactiveIcon,
  CodeButton,
  DotsHorizontalIcon,
  Dropdown,
  DropdownButton,
  ExclamationCircleIcon,
  Link,
  ListItem,
  Menu,
  MenuText,
  PendingActiveIcon,
  PendingInactiveIcon,
  PlayIcon,
  RelativeTime,
  Text,
  Code,
  Username,
  Hint,
  TeamContext,
} from 'ui'
import { useOuterClick, useUrls } from 'utils'
import styled from 'styled-components'
import WorkloadRunIngressButton from './WorkloadRunIngressButton'
import WorkloadRunDeleteButton from './WorkloadRunDeleteButton'
import WorkloadRunCommentUpdateButton from './WorkloadRunCommentButton'
import WorkloadRunRestartButton from './WorkloadRunRestartButton'

export const ACTIVE_STATES = [WorkloadRunLifecycleStatus.Running]

export const COMPLETE_STATES = [WorkloadRunLifecycleStatus.Complete]

export const FAILED_STATES = [
  WorkloadRunLifecycleStatus.Error,
  WorkloadRunLifecycleStatus.ReservationFailed,
  WorkloadRunLifecycleStatus.Failed,
  WorkloadRunLifecycleStatus.Deleted,
]

export const PENDING_ACTIVE_STATES = [
  WorkloadRunLifecycleStatus.FulfillingReservations,
  WorkloadRunLifecycleStatus.GeneratingYaml,
  WorkloadRunLifecycleStatus.New,
  WorkloadRunLifecycleStatus.Pending,
  WorkloadRunLifecycleStatus.RequestReservations,
  WorkloadRunLifecycleStatus.RequestStart,
  WorkloadRunLifecycleStatus.Scheduled,
]

export const PENDING_INACTIVE_STATES = [
  WorkloadRunLifecycleStatus.RequestStop,
  WorkloadRunLifecycleStatus.Stopping,
  WorkloadRunLifecycleStatus.ReleaseReservations,
]

export function LifecycleStatusIcon({
  lifecycleStatus,
}: {
  lifecycleStatus: WorkloadRunLifecycleStatus
}): JSX.Element {
  if (COMPLETE_STATES.includes(lifecycleStatus)) {
    return <CheckCircleInactiveIcon />
  } else if (FAILED_STATES.includes(lifecycleStatus)) {
    return <ExclamationCircleIcon />
  } else if (PENDING_ACTIVE_STATES.includes(lifecycleStatus)) {
    return <PendingActiveIcon />
  } else if (PENDING_INACTIVE_STATES.includes(lifecycleStatus)) {
    return <PendingInactiveIcon />
  } else if (ACTIVE_STATES.includes(lifecycleStatus)) {
    return <PlayIcon />
  }
  return <PendingActiveIcon />
}

function lifecycleStatusMessage(
  lifecycleStatus: WorkloadRunLifecycleStatus
): { text: string; isError: boolean } | null {
  switch (lifecycleStatus) {
    case WorkloadRunLifecycleStatus.Failed:
    case WorkloadRunLifecycleStatus.Error:
      return { text: 'Unexpected error', isError: true }
    case WorkloadRunLifecycleStatus.ReservationFailed:
      return { text: 'Failed to allocate requested resources', isError: true }
    default:
      return null
  }
}

const IngressRoutePill = styled.a`
  border-radius: 20px;
  color: white;
  background-color: purple;
  margin-left: 5px;
  padding: 0 10px;
  font-size: 12px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const TeamHardwareTag = styled.div`
  border-radius: 20px;
  color: #330;
  background-color: #fe6;
  margin-left: 5px;
  padding: 0 10px;
  font-size: 12px;
  text-decoration: none;
`

const StatusMessage: React.FC<{ statusMessage: string }> = ({
  statusMessage,
}): JSX.Element => {
  const indexOfDot = statusMessage.indexOf('.')
  const firstSentenceOr50Chars = indexOfDot == -1 ? 50 : indexOfDot
  return (
    <Code $secondary={true}>
      {`${statusMessage}`.substring(0, firstSentenceOr50Chars)}
      {/* Prepend newline so multi-line log is easier to read */}
      ... <Hint message={'\n' + statusMessage} />
    </Code>
  )
}

const CommentsButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  justify-content: left;
  text-align: left;
  white-space: pre;
  font-style: italic;
  &:hover {
    color: ${({ theme }) => theme.colors.font.secondary};
    font-weight: bold;
  }
`

const Comments: React.FC<{ comments: string; workloadRunId: string }> = ({
  comments,
  workloadRunId,
}): JSX.Element => {
  const workloadrun_comments = comments.trim()
  const indexOfNewline = workloadrun_comments.indexOf('\n')
  const firstLineOr50Chars =
    indexOfNewline > 50 || indexOfNewline < 0 ? 50 : indexOfNewline
  let firstLinePrinted = workloadrun_comments.substring(0, firstLineOr50Chars)
  if (workloadrun_comments.length > firstLineOr50Chars) {
    firstLinePrinted += '...'
  }

  const onClickHandler: MouseEventHandler<HTMLButtonElement> = (event) => {
    const elem = document.getElementById(event.currentTarget.id)
    if (elem) {
      if (
        event.currentTarget.id ==
        'comments-' + workloadRunId + 'compressed'
      ) {
        elem.innerText = workloadrun_comments
        elem.id = 'comments-' + workloadRunId + 'expanded'
      } else if (
        event.currentTarget.id ==
        'comments-' + workloadRunId + 'expanded'
      ) {
        elem.innerText = firstLinePrinted
        elem.id = 'comments-' + workloadRunId + 'compressed'
      }
    }
  }

  return (
    <CommentsButton
      onClick={onClickHandler}
      id={'comments-' + workloadRunId + 'compressed'}
    >
      {firstLinePrinted}
    </CommentsButton>
  )
}

export default function WorkloadRunListItem({
  workloadDefinition,
  workloadRun,
  teamId,
}: {
  workloadDefinition: WorkloadDefinitionType
  workloadRun: WorkloadRunType
  teamId: string
}): JSX.Element {
  const { ADMIN_URL } = useUrls()
  const { currentUser, userIsActiveTeamAdmin } = useContext(TeamContext)

  const [open, setOpen] = useState(false)
  const innerRef = useOuterClick(() => {
    setOpen(false)
  })

  const {
    pk,
    createdAt,
    lifecycleStatus,
    ingressRouteMatch,
    namespace,
    createdBy,
    requireTeamHardware,
  } = workloadRun

  const displayName =
    createdBy.firstName && createdBy.lastName
      ? `${createdBy.firstName} ${createdBy.lastName}`
      : createdBy.username

  const userIsCreator = currentUser.username === createdBy.username
  const createdAtDate = new Date(createdAt)
  const message = lifecycleStatusMessage(lifecycleStatus)

  const runtimeRequirementLabel = workloadRun.containerRuntimeRequirements.label
  const isUserAuthorised = userIsCreator || userIsActiveTeamAdmin
  const isActive =
    ACTIVE_STATES.includes(lifecycleStatus) ||
    PENDING_ACTIVE_STATES.includes(lifecycleStatus)
  const hasFullReplicaCount =
    workloadRun.workloadReplicas === workloadRun.workloadRunScale

  return (
    <ListItem>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            <LifecycleStatusIcon lifecycleStatus={lifecycleStatus} />
            {namespace && <CodeButton snippet={namespace} text={namespace} />}
            {requireTeamHardware && (
              <TeamHardwareTag>🔒 Team Hardware</TeamHardwareTag>
            )}
            <>
              {workloadRun.ingressrouteSet.map((ingressRoute) => {
                const url = new URL(ingressRoute.url)
                const href = url.toString()
                return (
                  <IngressRoutePill key={href} href={href} target={'_blank'}>
                    {url.hostname}
                  </IngressRoutePill>
                )
              })}
            </>
          </div>
          {message && <Text $error={message.isError}>{message.text}</Text>}
          {workloadRun.statusMessage && (
            <StatusMessage statusMessage={workloadRun.statusMessage} />
          )}
          <Text>
            <span
              style={{
                color: !isActive || hasFullReplicaCount ? 'inherit' : 'red',
              }}
            >
              ({workloadRun.workloadReplicas}/{workloadRun.workloadRunScale})
            </span>
            ×{workloadRun.containerFullImagePath.split('/').slice(-1)}
            {runtimeRequirementLabel ? ` on ${runtimeRequirementLabel}` : ''}
            {' | '}
            <Username
              style={{ display: 'inline' }}
              $isCurrentUser={userIsCreator}
            >{`${displayName}`}</Username>
            {'  '}
            <RelativeTime time={createdAtDate} />
          </Text>
          {workloadRun.comments && (
            <Comments
              comments={workloadRun.comments}
              workloadRunId={workloadRun.id}
            />
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}></div>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {lifecycleStatus === WorkloadRunLifecycleStatus.Running &&
          ingressRouteMatch && (
            <Link
              target="_blank"
              rel="noreferrer"
              href={`https://${ingressRouteMatch}`}
            >
              Open
            </Link>
          )}
        {[WorkloadRunLifecycleStatus.Running].includes(
          workloadRun.lifecycleStatus
        ) &&
          isUserAuthorised && (
            <WorkloadRunIngressButton
              workloadDefinition={workloadDefinition}
              workloadRun={workloadRun}
            />
          )}
        {[
          WorkloadRunLifecycleStatus.Running,
          WorkloadRunLifecycleStatus.Pending,
        ].includes(workloadRun.lifecycleStatus) &&
          isUserAuthorised && (
            <WorkloadRunDeleteButton
              teamId={teamId}
              workloadRun={workloadRun}
            />
          )}
        {isUserAuthorised && (
          <WorkloadRunCommentUpdateButton workloadRun={workloadRun} />
        )}
        <div style={{ position: 'relative' }} ref={innerRef}>
          <DropdownButton onClick={() => setOpen(!open)}>
            <DotsHorizontalIcon />
          </DropdownButton>
          {open && (
            <Dropdown direction="left" zIndex={'11'}>
              <Menu>
                <MenuText>Actions</MenuText>
                {lifecycleStatus === WorkloadRunLifecycleStatus.Running &&
                  ingressRouteMatch && (
                    <Link
                      target="_blank"
                      rel="noreferrer"
                      href={`https://${ingressRouteMatch}`}
                    >
                      Open
                    </Link>
                  )}
                {[WorkloadRunLifecycleStatus.Running].includes(
                  workloadRun.lifecycleStatus
                ) &&
                  isUserAuthorised && (
                    <>
                      <WorkloadRunIngressButton
                        workloadDefinition={workloadDefinition}
                        workloadRun={workloadRun}
                        buttonText="Ingress"
                      />
                      <WorkloadRunRestartButton
                        workloadRun={workloadRun}
                        buttonText="Restart"
                      />
                    </>
                  )}
                {[
                  WorkloadRunLifecycleStatus.Running,
                  WorkloadRunLifecycleStatus.Pending,
                ].includes(workloadRun.lifecycleStatus) &&
                  isUserAuthorised && (
                    <WorkloadRunDeleteButton
                      teamId={teamId}
                      workloadRun={workloadRun}
                      buttonText="Delete"
                    />
                  )}
                {isUserAuthorised && (
                  <WorkloadRunCommentUpdateButton
                    workloadRun={workloadRun}
                    buttonText="Comments"
                  />
                )}
                {currentUser.isSuperuser && (
                  <>
                    <MenuText>Admin</MenuText>
                    <Link
                      target="_blank"
                      rel="noreferrer"
                      href={`${ADMIN_URL}workloads/workloadrun/${pk}/change/`}
                    >
                      Detail Page
                    </Link>
                    <Link
                      target="_blank"
                      rel="noreferrer"
                      href={`https://grafana.tenstorrent.net/explore?orgId=1&left=%7B%22datasource%22:%22aYMTMaPVk%22,%22queries%22:%5B%7B%22refId%22:%22A%22,%22expr%22:%22%7Bnamespace%3D%5C%22${namespace}%5C%22%7D%20%7C%3D%20%60%60%22,%22queryType%22:%22range%22,%22editorMode%22:%22builder%22%7D%5D,%22range%22:%7B%22from%22:%22now-1h%22,%22to%22:%22now%22%7D%7D`}
                    >
                      Logs
                    </Link>
                    <Link
                      target="_blank"
                      rel="noreferrer"
                      href={`https://grafana.tenstorrent.net/explore?orgId=1&left=%7B%22datasource%22:%22HKKRdcEVk%22,%22queries%22:%5B%7B%22refId%22:%22A%22,%22expr%22:%22rate%28traefik_service_requests_total%7Bexported_service%3D~%5C%22${namespace}-.%2A%5C%22%7D%5B$__rate_interval%5D%29%22,%22range%22:true,%22editorMode%22:%22code%22%7D%5D,%22range%22:%7B%22from%22:%22now-1h%22,%22to%22:%22now%22%7D%7D`}
                    >
                      Ingress traffic
                    </Link>
                  </>
                )}
              </Menu>
            </Dropdown>
          )}
        </div>
      </div>
    </ListItem>
  )
}
